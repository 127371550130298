<template>
  <div :style="slidePosition" class="z-4 w-100 h-100">
    <div class="position-absolute text-warning seeing-with-antimatter "
      style="width:42.5%;left:6.5%;top:42.5%;transform:translate(0%,-50%)">
      <img src="images/Slide12/1982-seeing-with-antimatter-TRIUMF.jpg" style="width:100%" />
    </div>
    <div iv class="position-absolute text-white " style="width:40%;left:55%;top:40%;transform: translateY(-50%)">
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade12" style="opacity:0;">
        1982
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade12 " style="opacity:0; ">
        SEEING WITH ANTIMATTER
      </h6>
      <p class="font-vw-1-35 text-12" style="opacity:0; ">
        TRIUMF scientists, engineers, and technicians built one of Canada’s
        first fully functional Positron Emission Tomography (PET) scanners for
        human research. PET scanners use positrons, the antimatter cousins of
        electrons, to get an inside-out image of the body. TRIUMF’s scanner was
        relocated to the University of British Columbia Hospital where it served
        until 1996, using PET isotopes produced at TRIUMF, as a major diagnostic
        tool in fields from cancer to neuroscience research.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    slide7(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade12",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-12",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    antimatter(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 500
        })
        .add({
          targets: ".seeing-with-antimatter",
          opacity: [0, 1],
          easing: "easeInOutSine",
          duration: 500,
          delay: 1500
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.antimatter(this.innerPosition)

      this.slide7(this.innerPosition)
    }
  }
}
</script>

<style></style>
